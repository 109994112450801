<template>
  <div class="p-sidebar">
    <div class="p-sidebar__upper p-s pb-0">
      <UiCharAvatar :size="44" :text="displayName" />
      <h2 class="mt-xs block text-xl font-semibold">{{ displayName }}</h2>
    </div>

    <ul class="p-sidebar__items p-s">
      <li v-for="(tab, i) in tabs" :key="tab.value" style="list-style-type: none">
        <button
          :id="genClickId('profile', 'sidebar', tab.value)"
          :class="[
            'p-sidebar__item ps-item font-semibold',
            {
              active: currentTab === i,
              'ps-item--completed': tab.completed,
              'ps-item--waiting': tab.state.status === 'waiting',
              'ps-item--pending': tab.state.status === 'pending',
            },
          ]"
          @click="$emit('onTabChange', i)"
        >
          <!-- Icon -->
          <UiNuxtIcon class="ps-item__icon" :name="getIconByTab(tab)" filled />

          <div class="ps-item__text">
            <!-- Tab Text -->
            <span class="p-sidebar__item-text block" v-text="tab.text" />
            <!-- Tab Subtext -->
            <div class="dummy block" v-auto-animate>
              <span
                class="ps-item__subtitle text-muted-foreground block font-light leading-none"
                v-if="tab.state.description"
                v-text="tab.state.description"
              />
            </div>
          </div>
        </button>
      </li>
      <slot />
    </ul>
  </div>
</template>

<script setup lang="ts">
import { type SectionsTypes, getIconByTab } from "@finq/app-base/utils/profile/SectionsHelper"

const props = defineProps<{
  tabs: SectionsTypes.Tab[]
  currentTab: number
}>()

const { tabs, currentTab } = toRefs(props)

const { displayName } = useUser()
</script>

<style lang="scss" scoped>
.ps-item {
  display: flex;
  gap: theme.$spacing-xxs;
  cursor: pointer;

  &__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;

    .p-sidebar__item-text + * > :first-child {
      padding-top: theme.$spacing-xxs;
    }
  }

  &__icon {
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
  }

  &--pending {
    :deep(.ps-item__icon) {
      circle {
        fill: #feebe2;
      }
      path {
        stroke: #feebe2;
        fill: #e25e20;
      }
    }

    :deep(.ps-item__subtitle) {
      color: #e25e20;
    }
  }
}

.p-sidebar {
  &__upper {
    position: relative;
    .p-progress {
      margin-top: theme.$spacing-s;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: theme.$spacing-s;
  }

  &__item-text {
    &:hover {
      text-decoration: underline;
    }
  }

  @include theme.media("<md") {
    display: flex;
    flex-direction: column;

    &__upper {
      flex: 0.7;
      &::after {
        display: none;
      }
    }

    &__items {
      flex: 1;
      gap: calc(#{theme.$spacing-s} - 6px);
      margin: unset;
    }
  }
}
</style>
