<template>
  <div class="my-profile profile-content">
    <UiTwoColLayout :class="{ 'mt-mx': !bp.isMobile.value }">
      <template #start>
        <h2 class="text-lg font-semibold" v-if="bp.isMobile.value">
          {{ displayName }}
        </h2>

        <UiCard v-else :elevated="!bp.isMobile.value" body-padding="none">
          <!-- Profile Sidebar -->

          <PageProfileSidebar :tabs="tabs" :current-tab="currentTab" @onTabChange="onTabChange" />
        </UiCard>
      </template>

      <template #end>
        <slot />
      </template>
    </UiTwoColLayout>
  </div>
</template>

<script lang="ts">
import { mapState } from "pinia"

export default {
  name: "ProfileLayout",

  props: {
    currentTab: null,
    tabs: Array,
  },

  setup() {
    const { displayName } = useUser()
    const bp = useDisplay()

    return { displayName, bp }
  },

  computed: {
    ...mapState(useProfileStore, {
      saving: (state) => state.isSaving,
      getIsContractApproved: (state) => state.getIsContractApproved,
      //   getIsContractApproved: "profile/getIsContractApproved",
    }),
  },

  methods: {
    onSave(event: any) {
      this.$emit("onSave", event)
    },
    onTabChange(event: any) {
      this.$emit("onTabChange", event)
    },
  },
}
</script>

<style lang="scss" scoped>
:deep(.ip-layout) {
  @include theme.media("<=md") {
    gap: theme.$spacing-s;
  }
}
</style>
