import { ProfileFormSection } from "@finq/app-base/composables/stores/profile"

export namespace SectionsTypes {
  export type StatusTypes = "waiting" | "pending" | "locked"
  export interface Section {
    section: string
    props: Record<string, unknown>
  }

  export interface TranslationStateValue {
    description: string
  }

  export interface TranslationTab {
    text: string
    value: string
    states?: Record<StatusTypes, TranslationStateValue>
  }

  export interface Tab extends TranslationTab, ProfileFormSection {
    hasStates: boolean
    state: { status: StatusTypes } & TranslationStateValue
  }
}

export interface StatesMap {
  [key: string]: Record<string, any>
}

export function getSectionsByOrder(
  subscriptions: UserSubscription[],
  sectionsByProducts: Record<string, any> | undefined
) {
  const defaultSections = sectionsByProducts?.default

  if (!subscriptions?.length) {
    return defaultSections
  } else if (subscriptions?.length === 1) {
    return sectionsByProducts?.[subscriptions[0].productId] ?? defaultSections
  } else {
    // getting the plan sections with the largest amount of sections for the user
    return subscriptions.reduce((acc: string[], product: UserSubscription) => {
      const sections = sectionsByProducts?.[product.productId] ?? defaultSections

      return sections?.length! > acc.length ? sections : acc
    }, [])
  }
}

export function getTabsList(
  profileSections: SectionsTypes.Section[],
  translations: SectionsTypes.TranslationTab[],
  formSectionsInfo: ProfileFormSection[],
  statesMap: StatesMap
) {
  return profileSections
    .map(({ section }) => {
      const tab = translations.find((tab) => tab.value === section)

      if (!tab) return null

      const formSections =
        formSectionsInfo.find((sectInfo) => sectInfo.selector === tab.value + "Section") ||
        ({} as ProfileFormSection)

      let currState = {} as SectionsTypes.Tab["state"]

      if (!isNullOrUndefined(statesMap?.[section]) && !formSections?.completed) {
        // Finding the first state that is true from the statesMap
        const [stateKey] = (Object.entries(statesMap?.[section] ?? {})?.find(([, v]) => v === true) ||
          []) as [SectionsTypes.StatusTypes, boolean]

        const existingState = tab.states?.[stateKey]

        if (existingState) {
          currState = { status: stateKey, ...existingState }
        }
      }

      return {
        ...tab,
        ...formSections,
        state: currState,
        hasStates: !!Object.keys(currState).length,
      } as SectionsTypes.Tab
    })
    .filter(Boolean) as SectionsTypes.TranslationTab[]
}

export function getComponentsGrid(
  profileSections: SectionsTypes.Section[],
  tabs: SectionsTypes.TranslationTab[],
  componentsMap: Record<string, unknown>
) {
  return profileSections
    .map((section) => {
      const { section: sectionName, props } = section

      const component = componentsMap[sectionName]

      if (!component) return null

      return {
        sectionName,
        component,
        props: {
          id: sectionName + "Section",
          tab: tabs.find((tab) => tab.value === sectionName),
          ...props,
        },
      }
    })
    .filter(Boolean) as {
    [key: string]: unknown
    sectionName: string
  }[]
}

export function getIconByTab(tab: Pick<SectionsTypes.Tab, "completed" | "state" | "dirty" | "blocking">) {
  if (tab.dirty) {
    if (tab.completed && !tab.blocking) {
      return "profile/completed-waiting-for-save-icon"
    } else if (tab.completed && tab.blocking) {
      return "profile/blocking-section-waiting-for-save-icon"
    } else {
      return "profile/missing-icon"
    }
  } else {
    if (tab.completed && !tab.blocking) {
      return "profile/completed-icon"
    } else if (tab.completed && tab.blocking) {
      return "profile/blocking-section-icon"
    } else if (tab.state?.status === "waiting") {
      return "profile/locked-icon"
    } else {
      return "profile/missing-icon"
    }
  }
}
