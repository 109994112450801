import { defineAsyncComponent } from "vue"

import { maxLength, minLength, required, requiredIf } from "@vuelidate/validators"
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"

import { ProdCodesEnum } from "./../../composables/enums"
import { israeliCellValidation, israeliIdValidation } from "./IsraeliValidations"

dayjs.extend(customParseFormat)

const conditional = (validationMethod: any, condition: any) => (condition ? validationMethod : () => true)

const lazy = (component: any) =>
  defineAsyncComponent({
    loader: component,
    timeout: 4000,
    loadingComponent: () => null,
  })

export const Sections = {
  PersonalInfo: "PersonalInfo",
  PersonalFinancialProfile: "PersonalFinancialProfile",
  RegulatoryInfo: "RegulatoryInfo",
  FinqAgreement: "FinqAgreement",
  InvestmentAccount: "InvestmentAccount",
  Subscription: "Subscription",
} as const

export const SectionsComponentsMap = {
  [Sections.PersonalInfo]: lazy(
    () => import("@finq/app-base/components/page/profile/Sections/PersonalInfo/Section.vue")
  ),
  [Sections.PersonalFinancialProfile]: lazy(
    () => import("@finq/app-base/components/page/profile/Sections/PersonalFinancialProfile/Section.vue")
  ),
  [Sections.RegulatoryInfo]: lazy(
    () => import("@finq/app-base/components/page/profile/Sections/RegulatoryInfo/Section.vue")
  ),
  [Sections.FinqAgreement]: lazy(
    () => import("@finq/app-base/components/page/profile/Sections/FinqAgreement/Section.vue")
  ),
  [Sections.InvestmentAccount]: lazy(
    () => import("@finq/app-base/components/page/profile/Sections/InvestmentAccount/Section.vue")
  ),
  [Sections.Subscription]: lazy(
    () => import("@finq/app-base/components/page/profile/Sections/Subscription/Section.vue")
  ),
}

export const SectionsValidations = (ProdCode: (typeof ProdCodesEnum)[keyof typeof ProdCodesEnum]) => {
  const isInvest = ProdCode === ProdCodesEnum.Invest

  return {
    [Sections.PersonalInfo]: {
      UserInformationForm: {
        form: {
          firstName: {
            minLength: minLength(2),
            maxLength: maxLength(30),
            required: conditional(required, isInvest),
          },
          lastName: {
            minLength: minLength(2),
            maxLength: maxLength(30),
            required: conditional(required, isInvest),
          },
          cell: {
            israeliCellValidation,
            minLength: minLength(9),
            maxLength: maxLength(10),
            required: conditional(required, isInvest),
          },
        },
      },

      UserIdForm: {
        form: {
          idCardNumber: {
            israeliIdValidation,
            required: conditional(required, isInvest),
          },

          dateOfBirth: {
            validDate: (value: any) => {
              if (!value) return true

              return dayjs(value, "YYYY-MM-DD", true).isValid()
            },
            required: conditional(required, isInvest),
          },
        },
      },

      UserDocumentsForm: {
        documents: {
          $each: {
            subGroup: { required },
            details: {
              required: conditional(
                // @ts-ignore TODO: Check this
                requiredIf((d: any) => !d.optional),
                isInvest
              ),
              $each: {
                id: {
                  required: conditional(required, isInvest),
                },
              },
            },
          },
        },
      },

      UserNotificationsForm() {
        // TODO: Check this - should have access to this because this is a function
        if ((this as any).acceptedEmailProposal) {
          return { form: {} }
        } else {
          return {
            form: {
              city: { required },
              street: { required },
              homeNumber: {
                required,
                minLength: minLength(0),
                maxLength: maxLength(5),
              },
              postalCode: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(7),
              },
            },
          }
        }
      },
    },
  }
}
