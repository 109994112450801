import { helpers } from "@vuelidate/validators"

// Israeli Phone number validations
export const isIsraeliPhoneNumber = (phoneNumber: string | number) => {
  // Supports area code

  const localLandline = /^(?:0|\+972)[2-4,8,9][0-9]{7}$/ // 0AXXXXXXX or +972AXXXXXXX
  const localLandlineWithAreaCode = /^\(0[2-4,8,9]\) [0-9]{7}$/ // (0A) XXXXXXX
  const localMobile = /^(?:0|\+972)5[0-9]{8}$|^(?:\+9720)5[0-9]{8}$/ // 05NXXXXXXXX or +9725NXXXXXXXX or +97205NXXXXXXXX
  const localVoIP = /^(?:0|972)7[0-9]{8}$/ // 07NXXXXXXXX or +9727NXXXXXXXX
  const internationalLandline = /^\+972[2-4,8,9][0-9]{7}$/ // +972AXXXXXXX
  const internationalMobile = /^\+9725[0-9]{8}$/ // +9725NXXXXXXXX
  const _internationalMobile = /^9725[0-9]{8}/ // 9725NXXXXXXXX
  const internationalVoIP = /^\+9727[0-9]{8}$/ // +9727NXXXXXXXX
  const _internationalVoIP = /^9727[0-9]{8}$/ // 9727NXXXXXXXX

  const patterns = [
    localLandline,
    localLandlineWithAreaCode,
    localMobile,
    localVoIP,
    internationalLandline,
    internationalMobile,
    internationalVoIP,
    _internationalMobile,
    _internationalVoIP,
  ]

  return patterns.some((pattern) => pattern.test(String(phoneNumber).replace(/-/g, "")))
}

export const israeliCellValidation = (value: string | number) => {
  const phone = String(value).trim()

  return !helpers.req(value) || isIsraeliPhoneNumber(phone)
}

// Israeli ID validation
export const isIsraeliIdNumber = (id: string | number) => {
  let strId = String(id).trim()

  if (strId.length > 9) {
    return false
  }
  if (strId.length < 9) {
    while (strId.length < 9) strId = "0" + strId
  }
  let counter = 0
  let rawVal
  let actualVal

  for (let i = 0; i < strId.length; i++) {
    rawVal = Number(strId[i]) * ((i % 2) + 1)
    actualVal = rawVal > 9 ? rawVal - 9 : rawVal
    counter += actualVal
  }

  return counter % 10 === 0
}

export const israeliIdValidation = (value: string | number) => !helpers.req(value) || isIsraeliIdNumber(value)
