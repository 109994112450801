import { PortfolioData } from "../../../../../packages/portfolios/types"
import { Notifications } from "../../lib/types/my-inv"

export const DEFAULT_SHOW_BY = "value"

export const useMyInvestmentsStore = defineStore("my_inv", () => {
  ///// State
  const portfolio = ref<PortfolioData>()
  const showBy = ref<string>(DEFAULT_SHOW_BY)
  const progressStatus = ref<string>("")
  const notifications = ref<Notifications | null>(null)
  const isLoading = ref<boolean>(true)

  ///// Methods
  function setPortfolio(data: PortfolioData) {
    portfolio.value = data
  }
  const setShowBy = (data: string) => (showBy.value = data)
  const setProgressStatus = (data: string) => (progressStatus.value = data)
  const setNotifications = (data: Notifications | null) => (notifications.value = data)
  const toggleIsLoading = (loading: boolean) => (isLoading.value = loading)

  const decreaseUnSeenCount = ({ unSeenCount, mainScreenNotifications }: Notifications) => {
    if (unSeenCount - 1 < 0) return
    setNotifications({ mainScreenNotifications, unSeenCount: unSeenCount - 1 })
  }
  // decreaseUnSeenCount({ commit }, payload) {
  //   const { unSeenCount, mainScreenNotifications } = payload;

  //   if (unSeenCount - 1 < 0) return;

  //   commit("setNotifications", {
  //     ...mainScreenNotifications,
  //     unSeenCount: unSeenCount - 1,
  //   });
  // },

  const reset = () => {
    setPortfolio({} as PortfolioData)
    setShowBy(DEFAULT_SHOW_BY)
    setProgressStatus("")
    setNotifications(null)
    toggleIsLoading(true)
  }

  const getPortfolioAssets = computed(() => portfolio?.value?.assetsDetails ?? [])
  const getStocksOnly = computed(() =>
    getPortfolioAssets.value.filter(({ asset }) => asset.assetType === "S")
  )
  const getFundsOnly = computed(() => getPortfolioAssets.value.filter(({ asset }) => asset.assetType === "F"))
  const findAssetById = (id: string) => getPortfolioAssets.value.find(({ asset }) => asset.id === id)

  return {
    portfolio,
    showBy,
    progressStatus,
    notifications,
    isLoading,
    setPortfolio,
    setShowBy,
    setProgressStatus,
    setNotifications,
    toggleIsLoading,
    reset,
    findAssetById,
    decreaseUnSeenCount,
    getPortfolioAssets,
    getStocksOnly,
    getFundsOnly,
  }
})
