<template>
  <div :class="classNames.root">
    <slot />

    <div :class="classNames.layout">
      <!-- ip -> investment page -->
      <template v-for="{ tag, name, ...slot } in slotsByOrder" :key="name">
        <component v-bind="slot" :is="tag">
          <slot :name="name" />
        </component>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
export interface UiTwoColLayoutClasses {
  root?: ClassValue
  start?: ClassValue
  end?: ClassValue
  layout?: ClassValue
}

export interface UiTwoColLayoutProps {
  noGrid?: boolean
  noPadding?: boolean
  borderTop?: boolean
  reverse?: boolean
  stickyStart?: boolean
  classes?: UiTwoColLayoutClasses
}

const props = withDefaults(defineProps<UiTwoColLayoutProps>(), { stickyStart: true })

const slots = useSlots()

const classNames = computed((): Required<UiTwoColLayoutClasses> => {
  return {
    root: cn("container", { "p-0": props.noGrid || props.noPadding }, props.classes?.root),
    layout: cn("ip-layout", { "ip-layout--no-grid": props.noGrid }, props.classes?.layout),
    start: cn(
      "ip-layout__col ip-layout__col--start gap-8",
      {
        "ip-layout__col--sticky": props.stickyStart,
        "ip-layout__col--start-max-width": Boolean(slots.start),
      },
      props.classes?.start
    ),
    end: cn(
      "ip-layout__col ip-layout__col--end gap-8",
      { "ip-layout__col--end-max-width": Boolean(slots.end) },
      props.classes?.end
    ),
  }
})

const slotsByOrder = computed(() => {
  const aside = props.reverse ? "end" : "start"
  const main = props.reverse ? "start" : "end"

  const newSlots = [
    {
      name: main,
      tag: "main",
      class: classNames.value.end,
    },
  ]

  if (slots[aside]) {
    newSlots.unshift({
      name: aside,
      tag: "div",
      class: classNames.value.start,
    })
  }

  return props.reverse ? newSlots.reverse() : newSlots
})
</script>

<style lang="scss" scoped>
@mixin noGrid() {
  flex-wrap: wrap;
  gap: theme.$spacing-m;

  .ip-layout__col,
  .ip-layout__col--start {
    flex: 1 1 100% !important;
    max-width: 100%;
  }
}

.ip-layout {
  --gap: #{theme.$spacing-l};
  --start-width: 350px;
  --end-width: calc(100% - calc(var(--start-width) + var(--gap)));

  display: flex;
  align-items: flex-start;
  gap: var(--gap);

  .ui-card {
    width: 100%;
  }

  & + & {
    margin-top: 30px;
  }

  &__col {
    display: flex;
    flex-wrap: wrap;

    &--start,
    &--end {
      flex: 1;
      width: 100%;
    }

    &--start-max-width {
      max-width: var(--start-width);
    }

    &--end-max-width {
      max-width: var(--end-width);
    }

    @include theme.media(">md") {
      &--sticky {
        position: sticky;
        top: var(--col-sticky-top, #{theme.$nav-height + theme.$spacing-m});
      }
    }
  }

  &--no-grid {
    @include noGrid;
  }

  &--border-top {
    border-top: 1px solid theme.$lighter-gray;
    padding-top: theme.$spacing-m;
  }

  &--two-cols {
    display: flex;
    gap: theme.$spacing-m;
    width: 100%;

    @include theme.media(">md") {
      & > * {
        flex: 1 1 calc(50% - calc(#{theme.$spacing-m}) / 2);
      }
    }
  }

  @include theme.media("<md") {
    padding: 0 !important;
    @include noGrid;
    &--two-cols {
      flex-direction: column;
    }
  }
}
</style>
