import { HTTPError } from "ky"
import { merge } from "lodash-es"

import { User } from "../../lib/types/user"

const arrToKeyVal = (arr: any[], key: string, val: any) =>
  arr.reduce((newVal: any, item: any) => {
    newVal[item[key]] = item[val]

    return newVal
  }, {})

export interface ProfileSection {
  selector: string
  forms: string[]
}

export interface ProfileFormSection {
  selector: string
  completed: boolean
  invalid: boolean
  dirty: boolean
  blocking?: boolean
}

export interface UserDocument {
  approved: boolean
  details: UserDocumentDetails[]
  documentType: string
  group: string
  id: "BIOID" | "ID" | "PASSPORT" | "DLICENSE" | "OTHER"
  partnerId: number | null
  subGroup: string
  updateDate: number
}

export interface UserDocumentDetails {
  id: number
  name?: string
  url?: string
}

export interface UserDocumentGroupType {
  descriptionEn: string
  descriptionHe: string
  group: string
  id: string
  subGroup: string
}

export interface DocsProvider {
  isLoading: boolean
  getSecureDetailsUrls: (details: any[]) => Promise<UserDocumentDetails[]>
  removeFile: (fileId: number, documentId: any) => Promise<void>
  saveFiles: (
    files: (File | undefined)[],
    documentId: number,
    documentType: string,
    onUploadCallback?: (progress: number) => void
  ) => Promise<void>
  sbAlert: (text: string, err?: HTTPError) => void
  disableActions: boolean
}

// const getInitialState = () => ({
//   // User
//   savedUser: {} as User,
//   // Forms
//   forms: [] as ProfileFormSection[],
//   sections: [] as ProfileSection[],
//   isSaving: false,
//   profileMountDone: false,

//   documentsGroupTypes: {} as Record<string, UserDocumentGroupType[]>,
//   userDocuments: {} as Record<string, UserDocument[]>,
// });

export const useProfileStore = defineStore("Profile", () => {
  /// // State
  const savedUser = reactive<User>({} as User)
  const dirtyUserData = ref([] as (keyof User)[])
  const forms = reactive<ProfileFormSection[]>([])
  const sections = reactive<ProfileSection[]>([])
  const isSaving = ref(false)
  const profileMountDone = ref(false)
  const documentsGroupTypes = reactive<Record<string, UserDocumentGroupType[]>>({})
  const userDocuments = reactive<Record<string, UserDocument[]>>({})

  /// // Methods
  const setSavedUser = (user: User) => Object.assign(savedUser, user)

  const updateSavedUser = (user: Partial<User>) => Object.assign(savedUser, merge(savedUser, user))

  const setDirtyUserData = (val: (keyof User)[]) => (dirtyUserData.value = val)

  const updateCalculatedProfileProgress = () => updateSavedUser({ profileProgress: getProfileProgress.value })

  const setProfileMountDone = (value: boolean) => (profileMountDone.value = value)

  const toggleSaving = (val?: boolean) => (isSaving.value = val ?? !isSaving)

  const addSection = (section: ProfileSection) => {
    if (sections.find((s) => s.selector === section.selector)) return
    sections.push(section)
  }

  const addForm = (form: ProfileFormSection) => {
    if (forms.find((f) => f.selector === form.selector)) return
    forms.push(form)
  }

  const deleteForm = (selector: string) => {
    const index = forms.findIndex((form) => form.selector === selector)

    if (index === -1) return
    forms.splice(index, 1)
    updateCalculatedProfileProgress()
  }

  const updateForm = (selector: ProfileFormSection): void => {
    const index = forms.findIndex((form): boolean => form.selector === selector.selector)

    if (index === -1) return

    Object.assign(forms[index], selector)

    updateCalculatedProfileProgress()
  }

  const setDocumentsGroupTypes = (type: Record<string, UserDocumentGroupType>) =>
    Object.assign(documentsGroupTypes, merge(documentsGroupTypes, type))

  const setUserDocuments = (documents: Record<string, UserDocument>) =>
    Object.assign(userDocuments, merge(userDocuments, documents))

  const updateUserDocumentInGroup = ({ group, document }: { group: string; document: UserDocument }) => {
    const index = userDocuments[group].findIndex((doc) => doc.id === document.id)

    if (index === -1) {
      userDocuments[group].push(document)

      return
    }
    userDocuments[group][index] = document
  }

  /// // Getters
  const getCompletedForms = computed(() => forms.filter((form) => form.completed))

  const getInvalidForms = computed(() => forms.filter((form) => form.invalid))

  const getFormSectionsInfo = computed(() => {
    const completedForms = arrToKeyVal(forms, "selector", "completed")
    const dirtyForms = arrToKeyVal(forms, "selector", "dirty")
    const blockingForms = arrToKeyVal(forms, "selector", "blocking")

    const hasDirtyForm = (sec: ProfileSection) => sec.forms.some((f) => !!dirtyForms?.[f])
    const hasBlockingForm = (sec: ProfileSection) => sec.forms.some((f) => !!blockingForms?.[f])
    const allFormsCompleted = (sec: ProfileSection) => sec.forms.every((f) => !!completedForms?.[f])

    return sections.map((sec) => ({
      ...sec,
      blocking: sec.forms?.length ? hasBlockingForm(sec) : false,
      dirty: sec.forms?.length ? hasDirtyForm(sec) : false,
      completed: sec.forms?.length ? allFormsCompleted(sec) : false,
    }))
  })

  const getProfileProgress = computed(() => {
    const flatResult = getCompletedForms.value.length / forms.length

    return Math.ceil(flatResult * 100)
  })

  const getIsContractApproved = computed(
    (): boolean =>
      savedUser?.approvedContract ||
      Boolean(userDocuments.PERSONAL?.find(({ documentType }) => documentType === "CONTRACT")?.approved)
  )

  const getLockedSections = computed(() => {
    // If we want to ignore selections, add the section name to the array
    const ignoredSections = ["user-bank-information-form-section"]

    return forms.map((form) => form.selector).filter((selector) => !ignoredSections.includes(selector))
  })

  const getDocumentTypeInGroupById = (group: string, documentType: string) => {
    const types = documentsGroupTypes[group]

    return types?.find((doc) => doc.id === documentType)
  }

  const getUserDocumentInGroupById = (group: string, documentId: string) => {
    const groupDocuments = userDocuments[group]

    return groupDocuments?.find((doc) => doc.id === documentId)
  }

  /// // Reset
  const reset = () => {
    Object.assign(savedUser, {})
    dirtyUserData.value = []
    forms.splice(0, forms.length)
    sections.splice(0, sections.length)
    isSaving.value = false
    profileMountDone.value = false
    Object.assign(documentsGroupTypes, {})
    Object.assign(userDocuments, {})
  }

  return {
    // State
    savedUser,
    dirtyUserData,
    forms,
    sections,
    isSaving,
    profileMountDone,
    documentsGroupTypes,
    userDocuments,

    // Methods
    setSavedUser,
    updateSavedUser,
    setDirtyUserData,
    updateCalculatedProfileProgress,
    setProfileMountDone,
    toggleSaving,
    addSection,
    addForm,
    deleteForm,
    updateForm,
    setDocumentsGroupTypes,
    setUserDocuments,
    updateUserDocumentInGroup,

    // Getters
    getCompletedForms,
    getInvalidForms,
    getFormSectionsInfo,
    getProfileProgress,
    getIsContractApproved,
    getLockedSections,
    getDocumentTypeInGroupById,
    getUserDocumentInGroupById,
    // Reset
    reset,
  }
})
