import { isEqual, isObject, transform } from "lodash-es"

export const getDifferences = (
  object: Record<string, any>,
  base: Record<string, any>
): Record<string, any> => {
  return transform(
    object,
    (result: Record<string, any>, value: any, key: string) => {
      if (!isEqual(value, base?.[key])) {
        result[key] = isObject(value) && isObject(base?.[key]) ? getDifferences(value, base?.[key]) : value
      }
    },
    {}
  )
}
