import { isEmpty } from "lodash-es"

import { PricingPlanTypes, PricingProductEnum } from "@finq/pricing/types"

import { useMyInvestmentsStore } from "../../../../finq-finance/src/composables/store/my-investments"
import { ProfileFormSection, useProfileStore } from "../../composables/stores/profile"
import {
  SectionsTypes,
  StatesMap,
  getComponentsGrid,
  getSectionsByOrder,
  getTabsList,
} from "./SectionsHelper"
import { Sections, SectionsComponentsMap, SectionsValidations } from "./SectionsMaps"

export const useProfileSectionsManager = () => {
  const { tmsafe } = useI18nUtils()
  const user = useUser()
  const cfg = useAppConfig()

  const profileStore = useProfileStore()
  const { portfolio } = toRefs(useMyInvestmentsStore())
  const { profileMountDone, getFormSectionsInfo, dirtyUserData } = toRefs(profileStore)

  const userHasPortfolio = computed(() => isEmpty(!portfolio.value))

  const subscriptionSection = computed(() => {
    return cfg.features?.showSubscriptionProfileSection ? { section: Sections.Subscription } : {}
  })

  const sections = computed(() => {
    if (user.canAccessProduct(PricingProductEnum.Funds, [PricingPlanTypes.PlanCode.MANAGED])) {
      return {
        [PricingProductEnum.Funds]: [
          { section: Sections.PersonalInfo },
          { section: Sections.PersonalFinancialProfile },
          { section: Sections.RegulatoryInfo },
          { section: Sections.FinqAgreement },
          { section: Sections.InvestmentAccount },
          subscriptionSection.value,
        ],
      }
    } else {
      return { default: [{ section: Sections.PersonalInfo }, subscriptionSection.value] }
    }
  })

  const AllSectionsByProducts = computed(() => {
    return Object.fromEntries(
      Object.entries(sections.value).map(([prodCode, sections]) => {
        const currentProductValidations = SectionsValidations?.(
          prodCode as (typeof ProdCodesEnum)[keyof typeof ProdCodesEnum]
        )
        // @ts-ignore - section is not a valid value apparently
        const filteredSections = sections.filter((sect) => Boolean(Object.keys(sect).length))

        return [
          prodCode,
          filteredSections.map((sect: any) => {
            const { section } = sect
            // @ts-ignore - section is not a valid value apparently
            const sectionValidations = currentProductValidations?.[section] ?? null

            return {
              ...sect,
              props: {
                ...(("props" in sect && sect.props) || {}),
                sectionValidations,
              },
            }
          }),
        ]
      })
    )
  })

  const SectionsStatesMap = computed(() => {
    if (!profileMountDone.value) return {} as StatesMap

    const {
      PersonalInfo,
      RegulatoryInfo,
      PersonalFinancialProfile,
      FinqAgreement,
      InvestmentAccount,
      Subscription,
    } = Sections

    const investmentAccountWaiting = !isSectionsCompleted([PersonalInfo, RegulatoryInfo, FinqAgreement])

    const isBaseSectionsCompleted = isSectionsCompleted([PersonalInfo, PersonalFinancialProfile])

    const isInvestProduct = user.canAccessProduct(PricingProductEnum.Funds, [
      PricingPlanTypes.PlanCode.MANAGED,
    ])

    const unsavedData = Object.keys(dirtyUserData.value).length > 0

    return {
      [FinqAgreement]: {
        waiting: isInvestProduct
          ? !userHasPortfolio.value ||
            !(
              isBaseSectionsCompleted &&
              isSectionsCompleted([RegulatoryInfo]) &&
              !isSectionsCompleted([FinqAgreement]) &&
              !unsavedData
            )
          : !isBaseSectionsCompleted,
      },

      [InvestmentAccount]: {
        waiting: investmentAccountWaiting,
        pending: !investmentAccountWaiting /** && user has bank account data */,
      },

      [Subscription]: {
        pending: isInvestProduct ? !user.user.value?.bankAccountExist : false,
      },
    }
  })

  function getMatchingSections(requiredSections: (keyof typeof Sections)[]) {
    const fixedRequiredSections = requiredSections.map((section) => (section += "Section"))

    return getFormSectionsInfo.value.filter(({ selector }) => fixedRequiredSections.includes(selector))
  }

  function isSectionsCompleted(sections: (keyof typeof Sections)[]) {
    const matching = getMatchingSections(sections).every(({ completed, blocking }) => completed && !blocking)

    return matching
  }

  const sectionsManager = computed(() => {
    const translation = Object.values(tmsafe<SectionsTypes.TranslationTab[]>("profile_page.nav_tabs") ?? [])

    const baseSections = getSectionsByOrder(user.user.value?.subscriptions!, AllSectionsByProducts.value)

    const tabs = getTabsList(
      baseSections,
      translation,
      profileMountDone.value ? (getFormSectionsInfo.value as unknown as ProfileFormSection[]) : [],
      profileMountDone.value ? SectionsStatesMap.value : {}
    )

    const grid = getComponentsGrid(baseSections, tabs, SectionsComponentsMap)

    return {
      tabs,
      grid,
    }
  })

  return {
    sectionsManager,
  }
}
